<template>
  <b-img
    height="128"
    :src="countyLogoImage"
    alt="logo"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      /* eslint-disable global-require */
      countyLogoImage: require('@/assets/images/logo/kakamega-county-header-logo.png'),
      /* eslint-disable global-require */
    }
  },
}
</script>
